import { useDispatch, useSelector } from 'react-redux';

import { getOfferId, getRatesParams, getRequestId } from 'base/store/selectors';
import { ratesQuery } from 'base/store/actions';

export const useRatesRequest = () => {
  const dispatch = useDispatch();

  const offerId = useSelector(getOfferId);
  const requestId = useSelector(getRequestId);
  const ratesParams = useSelector(getRatesParams);

  const submitRequest = (params = {}) => {
    if (offerId && requestId && ratesParams) {
      dispatch(ratesQuery({ offerId, requestId, ...ratesParams, ...params }));
    }
  };

  return { submitRequest };
};
