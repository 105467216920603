/* eslint-disable no-useless-escape */
import { useEffect, useState } from 'react';

import { useResNumRatesRequest } from 'base/utils/API/requests/useResNumRatesRequest';
import { useLookUpRequest } from 'base/utils/API/requests/useLookUpRequest';

const executeRegex = (expression, text = '') =>
  expression.exec(text)?.groups || {};

/**
 * Pulls Preapproval-Number and Zip-Code from URL
 * Required URL hash format:
 *    #<9-or-10-digit-preapproval-nr>-<5-digit-zip-code>
 * OR
 *    #<first-name>-<last-name>-<5-digit-zip-code>
 * Examples:
 *    https://youarepreapproved.com/#123456789-12345
 *    https://youarepreapproved.com/#Dora-Aparicio-12345
 */
export const useHashParamsFromPath = location => {
  const [paramsHash, setParamsHash] = useState({
    resNum: '',
    firstName: '',
    lastName: '',
    zip: '',
  });

  useEffect(() => {
    const regexHash = /\#(?:(?<resNum>\d{9,10})|(?:(?<firstName>[^-]+)-(?<lastName>[^-]+)))\-(?<zip>\d{5})$/;

    const [resNum, zip, firstName] = location.hash
      ?.substring(1, location.hash?.length)
      .split('-');

    const { lastName } = executeRegex(regexHash, location?.hash || '');

    if (resNum && zip) {
      setParamsHash({
        resNum,
        firstName,
        lastName: null,
        zip,
      });
    } else if (firstName && lastName && zip) {
      setParamsHash({
        resNum: null,
        firstName,
        lastName: decodeURI(lastName),
        zip,
      });
    } else {
      setParamsHash({
        resNum: null,
        firstName,
        lastName: null,
        zip: null,
      });
    }
  }, [location]);

  return paramsHash;
};

/**
 * Pulls Preapproval-Number and Zip-Code from URL
 * Required URL hash format:
 *    #<9-or-10-digit-preapproval-nr>-<5-digit-zip-code>
 * Examples:
 *    https://youarepreapproved.com/123456789-12345
 */
export const useParamsFromPath = location => {
  const [params, setParams] = useState({
    resNum: '',
    zip: '',
  });

  useEffect(() => {
    const [resNum, zip] =
      location?.pathname ||
      (typeof window !== 'undefined' && window.location?.pathname) ||
      ''.split('-');

    if (resNum && zip) {
      setParams({
        resNum,
        zip,
      });
    } else {
      setParams({
        resNum: null,
        zip: null,
      });
    }
  }, [location]);

  return params;
};

/**
 * Pulls Source and Creative from URL, if provived
 * Required standard URL query format, has to be before hash
 * Example: https://youarepreapproved.com/?source=source&dataset=dataset&creative=creative#123456789-12345
 */
export const useSearchParamsFromPath = location => {
  const [paramsSearch, setParamsSearch] = useState({});

  useEffect(() => {
    const regexCreative = /creative\=(?<creative>[^?#&]+)/;
    const regexDataset = /dataset\=(?<dataset>[^?#&]+)/;
    const regexSource = /source\=(?<source>[^?#&]+)/;

    let { creative } = executeRegex(regexCreative, location?.search || '');
    let { dataset } = executeRegex(regexDataset, location?.search || '');
    let { source } = executeRegex(regexSource, location?.search || '');

    if (!source) {
      if ((location?.origin || '').includes('qr.')) {
        source = 'QR';
      }
    }

    creative = creative && decodeURIComponent(creative);
    dataset = dataset && decodeURIComponent(dataset);
    source = source && decodeURIComponent(source);

    setParamsSearch({ source, creative, dataset });
  }, [location]);

  return paramsSearch;
};

export const useLookupRequestFromPath = (location = {}) => {
  const { resNum, firstName, lastName, zip } = useHashParamsFromPath(location);
  const { creative, dataset, source } = useSearchParamsFromPath(location);

  const lookUpRequest = useLookUpRequest();
  const resNumRatesRequest = useResNumRatesRequest();

  const [areParamsLoaded, setAreParamsLoaded] = useState(false);

  useEffect(() => {
    if (resNum && zip) {
      resNumRatesRequest.submitRequest({
        resNum,
        zip,
        creative,
        dataset,
        source,
      });
      setAreParamsLoaded(true);
    }

    if (firstName && lastName && zip) {
      lookUpRequest.submitRequest({
        firstName,
        lastName,
        zip,
        creative,
        dataset,
        source,
      });

      setAreParamsLoaded(true);
    }

    if (
      resNum === null &&
      firstName === null &&
      lastName === null &&
      zip === null
    ) {
      return;
    }
  }, [resNum, zip, firstName, lastName]);

  return { areParamsLoaded, firstName };
};
