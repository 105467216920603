import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

export const Spinner = ({ label, theme = 'primary', variant }) => (
  <div {...{ className: styles.wrapper, variant }}>
    <div {...{ className: styles.spinner, theme }} />
    {label && <p {...{ className: styles.label }}>{label}</p>}
  </div>
);

Spinner.propTypes = {
  label: PropTypes.string,
  theme: PropTypes.string,
  variant: PropTypes.string,
};
