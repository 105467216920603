import { useDispatch } from 'react-redux';

import { lookupQuery } from 'base/store/actions';

export const useLookUpRequest = () => {
  const dispatch = useDispatch();

  const submitRequest = ({
    resNum,
    firstName,
    lastName,
    zip,
    creative,
    dataset,
    source,
  }) => {
    dispatch(
      lookupQuery({
        resNum,
        firstName,
        lastName,
        zip,
        creative,
        dataset,
        source,
      })
    );
  };

  return { submitRequest };
};
