import { useDispatch } from 'react-redux';
import { resNumRatesQuery } from 'base/store/actions';

export const useResNumRatesRequest = () => {
  const dispatch = useDispatch();

  const submitRequest = ({
    resNum,
    zip,
    creative,
    dataset,
    source = 'Web',
  }) => {
    if (resNum) {
      dispatch(
        resNumRatesQuery({
          resNum,
          zip,
          creative,
          dataset,
          source,
        })
      );
    }
  };

  return { submitRequest };
};
